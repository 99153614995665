import React from 'react';
import styled from 'styled-components';

const ParagraphStyled = styled.h1`
    font-weight: 300;
    color: ${props => !props.fontColor ? 'black' : props.fontColor};
    font-size: ${props => !props.fontSize ? '16' : props.fontSize}px;

    @media ${props => props.theme.media.mobileL} {
        font-size: ${props => !props.fontSize ? '14' : (props.fontSize * 0.8) }px;
    }
`;

const Paragraph = (props) => {
    return (
        <ParagraphStyled {...props}>
            {props.children}
        </ParagraphStyled>
    )
}

export default Paragraph;