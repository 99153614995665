import React from 'react';
import styled from 'styled-components';

const HeadingStyled = styled.h1`
    font-weight: 300;
    color: ${props => !props.fontColor ? 'black' : props.fontColor};
    font-size: ${props => !props.fontSize ? '72' : props.fontSize}px;

    @media ${props => props.theme.media.tabletL} {
        font-size: ${props => !props.fontSize ? '55' : (props.fontSize * 0.9) }px;
    }
    @media ${props => props.theme.media.mobileL} {
        font-size: ${props => !props.fontSize ? '42' : (props.fontSize * 0.75) }px;
    }
`;

const Heading = (props) => {
    return (
        <HeadingStyled {...props}>
            {props.children}
        </HeadingStyled>
    )
}

export default Heading;