import React, { Component } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { theme } from '../../styles/theme';
import { GlobalStyle } from '../../styles/globalStyle';

import Header from './Header/Header';

const LayoutStyled = styled.div`
    position: relative;
    height: 100%;
`;

class Layout extends Component {
    render() {
        console.log('layout re-renders')
        return (
            <ThemeProvider theme={theme}>
                <>
                    <GlobalStyle />

                    <LayoutStyled>
                        <Header scrolled={!this.props.isAtTop} windowWidth={this.props.windowWidth}/>
                        {this.props.children}
                    </LayoutStyled>

                </>
            </ThemeProvider>
        )
    }
}

export default Layout;