import React from 'react';
import styled from 'styled-components';

import { grow } from '../../styles/animations';

const ButtonStyled = styled.div`
    background-color: ${props => props.theme.colors.primaryAccent};
    border-radius: 100px;
    color: ${props => props.theme.colors.light};
    text-transform: uppercase;
    font-weight: bold;
    font-size: 24px;
    padding: 20px 50px;
    display: inline-block;
    margin: 20px 0;
    cursor: pointer;

    &:hover {
        animation: ${grow} 0.2s linear;
        animation-fill-mode: forwards;
    }

    @media ${props => props.theme.media.tabletL} {
        font-size: 18px;
    }
`;

const Button = (props) => {
    return (
        <ButtonStyled {...props}>
            {props.children}
        </ButtonStyled>
    )
}

export default Button;