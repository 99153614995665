import React from 'react';
import styled from 'styled-components';
import { graphql, StaticQuery } from 'gatsby';

import Heading from '../UI/Heading';
import Paragraph from '../UI/Paragraph';
import { LocalPhone, Email } from 'styled-icons/material';
import AnchorLink from '../UI/AnchorLink';

const ContactSectionStyled = styled.div`
    width: 100%;
    padding: 55px ${props => props.theme.sizes.contentMargin};
    background-color: ${props => props.theme.colors.mediumDark};
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    position: relative;

    @media ${props => props.theme.media.tabletS} {
        flex-direction: column;
        text-align: center;
        align-items: center;
    }
`;

const ColumnWrapper = styled.div`
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    @media ${props => props.theme.media.tabletS} {
        width: 100%;
        align-items: center;
    }
`;

const ColumnWrapperRight = styled(ColumnWrapper)`
    align-items: flex-end;
    @media ${props => props.theme.media.tabletS} {
        margin-top: 20px;
    }
`;

const RowWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const CustomParagraph = styled(Paragraph)`
    padding-left: 20px;
`;

const ContactSection = (props) => {
    const contact = props.data.sanityContact
    return (
        <ContactSectionStyled {...props}>
            <AnchorLink id='contact'></AnchorLink>
            <ColumnWrapper>
                <Heading fontSize='40' fontColor={props => props.theme.colors.primaryAccent}>Contact</Heading>
                <Paragraph>{contact.text}</Paragraph>
            </ColumnWrapper>

            <ColumnWrapperRight>
                <RowWrapper>
                    <LocalPhone size='30px' /> <CustomParagraph fontSize='24'>{contact.phone}</CustomParagraph>
                </RowWrapper>
                <RowWrapper>
                    <Email size='30px' /> <CustomParagraph fontSize='24'>{contact.email}</CustomParagraph>
                </RowWrapper>
            </ColumnWrapperRight>
        </ContactSectionStyled>
    )
}

export default props => (
    <StaticQuery
        query={graphql`
          query {
            sanityContact {
                phone
                text
                email
              }
          }
        `}
        render={data => <ContactSection data={data} {...props} />}
    />
);