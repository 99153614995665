import React, { Component } from 'react';
import styled from 'styled-components';
import { graphql, StaticQuery } from 'gatsby';
import Img from 'gatsby-image';

import Heading from '../UI/Heading';
import Paragraph from '../UI/Paragraph';
import QtyCounter from '../QtyCounter/QtyCounter';
import AnchorLink from '../UI/AnchorLink';
import ToggleSwitch from '../UI/ToggleSwitch';

const BuySectionStyled = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    padding: ${props => props.theme.sizes.contentMargin};
`;

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    @media ${props => props.theme.media.tabletL} {
        flex-direction: column;
    }
    @media ${props => props.theme.media.mobileL} {
        margin: ${props => props.theme.sizes.resContentMargin};
    }
`;

const CustomParagraph = styled(Paragraph)`
    padding: 20px 0;
`;

const Left = styled.div`
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media ${props => props.theme.media.tabletL} {
        width: 100%;
    }
    @media ${props => props.theme.media.mobileL} {
        text-align: center;
        align-items: center;
    }
`;

const Right = styled.div`
    width: 40%;
    display: flex;
    justify-content: flex-end;

    @media ${props => props.theme.media.tabletL} {
        width: 100%;
        justify-content: center;
        padding-top: 20px;
    }
`;

const ProductImage = styled(Img)`
    width: 80%;
    max-height: 300px;
    border-radius: 20px;
    padding: 10px;
`;

const FlexContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    user-select: none;


    @media ${props => props.theme.media.mobileL} {
        flex-direction: column;
    }
`;

const Button = styled.button`
    background-color: ${props => props.theme.colors.light};
    border-radius: 100px;
    color: ${props => props.theme.colors.primaryAccent};
    border: 4px solid ${props => props.theme.colors.primaryAccent};
    text-transform: uppercase;
    font-weight: bold;
    font-size: 20px;
    padding: 20px 30px;
    margin: 20px 0 20px 20px;
    cursor: pointer;

    &:hover {
        background-color: ${props => props.theme.colors.medium};
    }
    @media ${props => props.theme.media.tabletL} {
        font-size: 18px;
    }
    @media ${props => props.theme.media.mobileL} {
        margin: 20px;
    }
`;

const SmallText = styled.small`
    font-size: 14px;
    padding: 0 3px;
`;

class BuySection extends Component {
    state = {
        qtyCount: 1,
        inclVat: false,
        stock: null,
    }

    componentDidMount() {
        const sanityClient = require('@sanity/client')
        const client = sanityClient({
            projectId: '9l2wdb9p',
            dataset: 'website',
            useCdn: false
        })
        this.getStock(client)

        if (window.Snipcart) {
            window.Snipcart.subscribe('item.added', (item) => {
                // NEED TO PUT THIS GET STOCK THING IN AN EVENT FOR ORDER COMPLETED OR SOMETHING
                const newStock = this.getStock(client)
                this.setState({ qtyCount: 1, stock: newStock })
            })

            window.Snipcart.subscribe('item.adding', (ev, item, items) => {
                const currentQty = window.Snipcart.api.items.count()
                const stock = this.state.stock
                let updatedQty = (currentQty + this.state.qtyCount) - 1
                if (updatedQty > stock) {
                    updatedQty = stock - 1
                }
                if (currentQty > 0) {
                    window.Snipcart.api.items.update('1', {
                        "quantity": updatedQty,
                        "maxQuantity": stock,
                    })
                }
                return ev;
            })
        }
    }

    componentWillUnmount() {
        window.Snipcart.unsubscribe('item.added');
        window.Snipcart.unsubscribe('item.adding');
    }

    getStock = (client) => {
        client.getDocument('single-product').then(product => {
            this.setState({ stock: product.stock })
        })
    }

    incrementQty = () => {
        this.setState((state => {
            return { qtyCount: state.qtyCount + 1 }
        }))
    }

    decrementQty = () => {
        if (this.state.qtyCount > 1) {
            this.setState((state => {
                return { qtyCount: state.qtyCount - 1 }
            }))
        }
    }

    toggleChange = () => {
        this.setState(state => ({
            inclVat: !state.inclVat
        }))
    }

    render() {
        const product = this.props.data.product.edges[0].node
        const price = this.state.inclVat ? product.price * 1.2 : product.price
        const buttonExclVat = (
            <Button
                style={this.state.inclVat ? { display: 'none' } : { display: 'inline-block' }}
                className='snipcart-add-item'
                data-item-id='excl'
                data-item-price={product.price}
                data-item-name={product.name}
                data-item-description={product.description}
                data-item-quantity={this.state.qtyCount > this.state.stock ? this.state.stock : this.state.qtyCount}
                data-item-image={product.image[0].asset.fluid.src}
                data-item-url="https://www.aerotag.co.uk"
                data-item-has-taxes-included={false}
                data-item-taxes='VAT'
                data-item-max-quantity={this.state.stock}
            >
                Add to basket
                    </Button>
        )
        const buttonInclVat = (
            <Button
                style={this.state.inclVat ? { display: 'inline-block' } : { display: 'none' }}
                className='snipcart-add-item'
                data-item-id='incl'
                data-item-price={product.price * 1.2}
                data-item-name={product.name}
                data-item-description={product.description}
                data-item-quantity={this.state.qtyCount > this.state.stock ? this.state.stock : this.state.qtyCount}
                data-item-image={product.image[0].asset.fluid.src}
                data-item-url="https://www.aerotag.co.uk"
                data-item-has-taxes-included={true}
                data-item-taxes='VAT_incl'
                data-item-max-quantity={this.state.stock}
            >
                Add to basket
                    </Button>
        )
        return (
            <BuySectionStyled {...this.props}>
                <AnchorLink id='buy'></AnchorLink>
                <Wrapper>
                    <Left>
                        <Heading fontSize='36' >Buy the <strong>{product.name}</strong></Heading>
                        <FlexContainer>
                            <ToggleSwitch checked={this.state.inclVat} toggleChange={this.toggleChange} />
                            <Heading fontSize='36' fontColor={props => props.theme.colors.primaryAccent} >
                                <strong>£{price.toFixed(2)}</strong>
                                <SmallText>{this.state.inclVat ? 'incl' : 'excl'}. VAT</SmallText>
                            </Heading>
                        </FlexContainer>
                        <CustomParagraph>{product.description}</CustomParagraph>
                        <CustomParagraph>{product.bulk}</CustomParagraph>
                        <FlexContainer>
                            <QtyCounter
                                qty={this.state.qtyCount}
                                increment={this.incrementQty}
                                decrement={this.decrementQty} />
                            {buttonInclVat}
                            {buttonExclVat}
                        </FlexContainer>
                    </Left>
                    <Right>
                        <ProductImage fluid={product.image[0].asset.fluid} />

                    </Right>
                </Wrapper>
            </BuySectionStyled>
        )
    }
}

export default props => (
    <StaticQuery
        query={graphql`
          query {
            product: allSanityProduct {
                edges {
                  node {
                    name
                    description
                    bulk
                    price
                    stock
                    image {
                        asset {
                            fluid {
                                ...GatsbySanityImageFluid
                            }
                        }
                    }
                  }
                }
              }
            background: file(name: {eq: "chair-background"}) {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
          }
        `}
        render={data => <BuySection data={data} {...props} />}
    />
);
