export const theme = {
    colors: {
        primaryAccent: '#00B7D9', //blue
        light: '#fff', //white
        medium: '#E7E7E7', //grey
        mediumDark: '#c4c4c4', // dark grey
        dark: '#080708', //black
        opacityLight: 'rgba(255, 255, 255, 0.5 )' //white semi-transparent
    },
    fonts: {
        primary: '"Open Sans", sans-serif'
    },
    sizes: {
        contentWidth: '80%',
        contentMargin: '10%',
        resContentWidth: '85%',
        resContentMargin: '7.5%',
        headerHeight: '80px',
        navBarHeight: '40px',
        combinedHeaderHeight: '150px',
        footerHeight: '80px',
        angledSeparator: '10vh'
    },
    media: {
        tabletL: '(max-width: 1000px)',
        tabletS: '(max-width: 800px)',
        mobileL: '(max-width: 500px)',
        mobileS: '(max-width: 320px)'
    }

};