import React from 'react';
import styled from 'styled-components';

import { Plus, Minus } from 'styled-icons/boxicons-regular';

const QtyCounterStyled = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Qty = styled.div`
    padding: 10px;
    font-weight: bold;
`;

const Button = styled.div`
    padding: 2px;
    font-weight: bold;
    background-color: ${props => props.theme.colors.medium};
    color: ${props => props.theme.colors.dark};
    border-radius: 50%;
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
        background-color: ${props => props.theme.colors.mediumDark};
    }
`;

const QtyCounter = (props) => {

return (
<QtyCounterStyled>
    <Button onClick={props.decrement}><Minus size='20px' /></Button>
    <Qty>
        {props.qty}
    </Qty>
    <Button onClick={props.increment}><Plus size='20px' /></Button>
</QtyCounterStyled>
)
}

export default QtyCounter;