import React from 'react';
import styled from 'styled-components';

import { ShoppingCart } from 'styled-icons/material';

const CartIconStyled = styled.div`
    position: relative;
    padding: 0 10px;
    cursor: pointer;
`;

const Counter = styled.span`
    background: ${props => props.theme.colors.primaryAccent};
    color: ${props => props.theme.colors.dark};
    font-size: 12px;
    font-weight: 700;
    border-radius: 50%;
    padding: 4px;
    position: absolute;
    top: 0;
    right: 0;
`;

const CartIcon = (props) => {
    return (
        <CartIconStyled className="snipcart-checkout">
            <ShoppingCart size='28' />
            <Counter>
                {props.items}
            </Counter>
        </CartIconStyled>
    )
}

export default CartIcon;