import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import largeLogo from '../../../images/logo.png';
import smallLogo from '../../../images/small-logo.png'
import NavBar from './NavBar';

const HeaderStyled = styled.div`
    width: 100%;
    height: ${props => props.theme.sizes.headerHeight};
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10%;

    ${props => props.scrolled && `
        background-color: ${props.theme.colors.mediumDark};
    `}
`;

const LogoStyled = styled.img`
    height: 100%;
    padding: 10px;
`;

const LinkStyled = styled(Link)`
    height: 100%;
`;

const Header = (props) => {
    if (typeof window !== "undefined") {
        require("smooth-scroll")('a[href*="#"]')
    }

    return (
        <HeaderStyled id='top' {...props}>
            <LinkStyled to='/'> 
            <LogoStyled src={props.windowWidth > 600 ? largeLogo : smallLogo} alt='logo' /> 
            </LinkStyled>
            <NavBar />
        </HeaderStyled>
    )
}

export default Header;

