import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';

import Heading from '../UI/Heading';
import Paragraph from '../UI/Paragraph';

const ProductInfoStyled = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${props => props.secondary ? props.theme.colors.medium : props.theme.colors.light};
    text-align:  ${props => props.secondary ? 'right' : 'left'};
    flex-direction: ${props => props.secondary ? 'row-reverse' : 'row'};
    padding: calc(${props => props.theme.sizes.angledSeparator} + 50px) ${props => props.theme.sizes.contentMargin};
    clip-path: ${props => props.secondary ? 'polygon(0 0, 100% 0, 100% calc(100% - ' + props.theme.sizes.angledSeparator + '), 0% 100%)' : 'polygon(0 0, 100% 0, 100% 100%, 0% calc(100% - ' + props.theme.sizes.angledSeparator + '))'};
    margin-top: -${props => props.theme.sizes.angledSeparator};

    ${props => props.last && `
        clip-path: none;
        padding-bottom: calc(${props.theme.sizes.angledSeparator} + 20px);
    `}

    @media ${props => props.theme.media.tabletS} {
        flex-direction: column;
        text-align: center;
    }

    @media ${props => props.theme.media.mobileL} {
        padding: calc(${props => props.theme.sizes.angledSeparator} + 50px) ${props => props.theme.sizes.resContentMargin};
    }
`;

const TextWrapper = styled.div`
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items:  ${props => props.secondary ? 'flex-end' : 'flex-start'};
    margin: 0 20px;

    @media ${props => props.theme.media.tabletS} {
        width: 100%;
        align-items: center;
    }
`;

const Emphasis = styled.span`
    color: ${props => props.theme.colors.primaryAccent};
`;

const ImageStyled = styled(Img)`
    width: 50%;
    margin: 20px 20px;
    max-width: 322px;

    @media ${props => props.theme.media.tabletS} {
        width: 100%;
    }
`;

const Line = styled.div`
    height: 4px;
    width: 60%;
    background-color: ${props => props.theme.colors.mediumDark};
    margin: 20px 0;

    @media ${props => props.theme.media.tabletS} {
        margin: 20px auto;
    }
`;

const ProductInfo = (props) => {
    const titleArray = props.title.split(' ');
    return (
        <ProductInfoStyled className={props.className} {...props}>
            <TextWrapper secondary= {props.secondary}>
                <Heading fontSize='48'> <Emphasis>{titleArray[0]}</Emphasis> {titleArray.splice(1).join(' ')}</Heading>
                <Line />
                <Paragraph>{props.children}</Paragraph>
            </TextWrapper>
            <ImageStyled fluid={props.image} />
        </ProductInfoStyled>
    )
}

export default ProductInfo;