import React from 'react';
import styled from 'styled-components';

const ToggleSwitchStyled = styled.label`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 30px;
    width: 150px;
    border-radius: 100px;
    margin: 20px 0;
    position: relative;
    cursor: pointer;
    background: ${props => props.theme.colors.medium};
`;

const Text = styled.div`
    width: 50%;
    text-align: center;
    position: relative;
    z-index: 2;
    font-size: 0.7em;
    font-weight: 700;
`;

const InputStyled = styled.input`
    opacity: 0;
    display: none;
    width: 0;
    height: 0;
`;

const Slider = styled.span`
        position: absolute;
        z-index: 1;
        cursor: pointer;
        top: 0px;
        left: 0px;
        height: 30px;
        width: 75px;
        background: ${props => props.theme.colors.primaryAccent};
        border-radius: 100px;
        transition: .4s;

        ${props => props.checked && `
            transform: translateX(75px);
    `}
`;


const ToggleSwitch = (props) => {
    return (
        <ToggleSwitchStyled htmlFor='toggle' {...props}>
            <Text>Excl. VAT</Text>
            <Text>Incl. VAT</Text>
            <InputStyled type="checkbox" id='toggle' name='toggle' checked={props.checked} onChange={props.toggleChange}></InputStyled>
            <Slider {...props}></Slider>
        </ToggleSwitchStyled>
            )
        }
        
export default ToggleSwitch;