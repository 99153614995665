import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

const NavItem = styled(Link)`
    padding: 10px 0;
    margin: 0 20px;
    font-weight: 700;
    font-size: 18px;
    border-bottom: 4px solid transparent;
    border-top: 4px solid transparent;

    :hover {
        border-bottom: 4px solid ${props => props.theme.colors.primaryAccent};
    }
`;

const NavItems = ({className, children, close}) => {
    return (
        <>
            <NavItem onClick={close} className={className} to='/#top'>HOME</NavItem>
            <NavItem onClick={close} className={className} to='/#product'>PRODUCT</NavItem>
            <NavItem onClick={close} className={className} to='/#buy'>BUY</NavItem>
            <NavItem onClick={close} className={className} to='/#contact'>CONTACT</NavItem>
        </>
    )
}

export default NavItems