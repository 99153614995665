import React, { Component } from 'react';
import styled from 'styled-components';

import NavItems from './NavItems';
import { Menu, Close } from 'styled-icons/material';
import CartIcon from './CartIcon';

const NavBarStyled = styled.nav`
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 100%;
`;

const Hamburger = styled.div`
    display: none;
    cursor: pointer;
    padding: 10px;
    @media ${props => props.theme.media.tabletL} {
        display: inline-block;
    }
    :hover {
        color: ${props => props.theme.colors.primaryAccent};
    }
`;

const ItemsWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;
    @media ${props => props.theme.media.tabletL} {
        display: none;
    }
`;

const ResponsiveItemsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    position: fixed;
    top: 80px;
    right: 0;
    background: ${props => props.theme.colors.primaryAccent};
    border-radius: 20px;
    width: 40%;
    height: auto;
    padding: 10px;
    margin: 20px;

    &::after {
        content: " ";
        bottom: 100%;
        position: absolute;
        left: 74%;
        margin-left: -20px;
        border-width: 20px;
        border-style: solid;
        border-color: transparent transparent ${props => props.theme.colors.primaryAccent}; transparent;
    }
`;

class NavBar extends Component {
    state = {
        navOpen: false,
        items: 0
    }

    componentDidMount() {
        if (window.Snipcart) {
            //this allows it to work when switching pages
            var count = window.Snipcart.api.items.count();
            this.updateItemTotal(count)

            //this allows it to work when you add or change items
            window.Snipcart.subscribe('cart.closed', () => {
                var count = window.Snipcart.api.items.count();
                this.updateItemTotal(count)
            });

            //this allows it to work on refreshing the page
            window.Snipcart.subscribe('cart.ready', (data) => {
                var count = window.Snipcart.api.items.count();
                this.updateItemTotal(count)
            })
        }
    }

    componentWillUnmount() {
        window.Snipcart.unsubscribe('cart.closed');
        window.Snipcart.unsubscribe('cart.ready');
    }

    hamburgerMenuToggle = () => {
        this.setState((prevState => {
            return { navOpen: !this.state.navOpen }
        }))
    }

    closeNavBar = () => {
        if (this.state.navOpen) {
            this.setState({ navOpen: false })
        }
    }

    updateItemTotal = (qty) => {
        this.setState({ items: qty })
    }

    render() {
        if (typeof window !== `undefined`) {
            window.onresize = () => {
                if (window.innerWidth > 1000 && this.state.navOpen) {
                    this.closeNavBar()
                }
            }
        }

        let responsiveMenu = null
        if (this.state.navOpen) {
            responsiveMenu = (
                <ResponsiveItemsWrapper>
                    <NavItems close={this.closeNavBar}/>
                </ResponsiveItemsWrapper>
            )
        }

        return (
            <NavBarStyled>
                <ItemsWrapper>
                    <NavItems close={this.closeNavBar} />
                </ItemsWrapper>
                {responsiveMenu}

                <Hamburger onClick={this.hamburgerMenuToggle}>
                    {!this.state.navOpen ? <Menu size='30' /> : <Close size='30' />}
                </Hamburger>
                <CartIcon items={this.state.items} />
            </NavBarStyled>
        )
    }
}

export default NavBar