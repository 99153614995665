import React from 'react';
import styled from 'styled-components';

const AnchorLinkStyled = styled.a`
    position: absolute;
    top: -${props => props.theme.sizes.headerHeight};
`;

const AnchorLink = (props) => {
return (
<AnchorLinkStyled {...props}>

</AnchorLinkStyled>
)
}

export default AnchorLink;