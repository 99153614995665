import {keyframes} from 'styled-components';

export const grow = keyframes`
    from {
        transform: scale(1);
    }

    to {
        transform: scale(1.1);
    }
`;