import React, { Component } from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import throttle from 'lodash.throttle'

//import Img from 'gatsby-image';

import Layout from '../components/Layout/Layout';
import HeroImage from '../components/HeroImage/HeroImage';
import ProductInfo from '../components/ProductInfo/ProductInfo';
import ProductInfoImage from '../components/ProductInfo/ProductInfoImage';
import BuySection from '../components/BuySection/BuySection';
import ContactSection from '../components/ContactSection/ContactSection';
import AnchorLink from '../components/UI/AnchorLink';
import CustomHelmet from '../components/CustomHelmet/CustomHelmet';

// This is required to make the z-index work correctly for the products. Products should be listed in reverse order.
const ProductWrapper = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column-reverse;
    position: relative;
`;

class Index extends Component {
    state = {
        windowHeight: null,
        atTop: true,
        windowWidth: null
    }

    componentDidMount() {
        this.setState({ windowHeight: window.innerHeight, windowWidth: window.innerWidth });
        window.addEventListener('scroll', throttle(this.handleScroll, 300));
        window.addEventListener('resize', throttle(this.handleResize, 300));
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', throttle(this.handleScroll, 300))
        window.removeEventListener('resize', throttle(this.handleResize, 300))
    }

    handleScroll = () => {
        if (window.pageYOffset === 0) {
            this.setState({ atTop: true })
        } else if (window.pageYOffset > 0 && this.state.atTop) {
            this.setState({ atTop: false })
        }
    }

    handleResize = () => {
        const { innerWidth: width, innerHeight: height } = window;
        this.setState({ windowHeight: height, windowWidth: width });
    }

    render() {
        const teasers = this.props.data.teasers
        const seo = this.props.data.seo
        return (
            <Layout isAtTop={this.state.atTop} windowWidth={this.state.windowWidth}>
                <CustomHelmet
                    title={seo.title}
                    description={seo.description}
                    image={seo.image.asset.fluid.src}
                    url='https://www.aerotag.co.uk'
                />
                <AnchorLink id='top'></AnchorLink>
                <HeroImage height={this.state.windowHeight} />
                <ProductWrapper>
                    <AnchorLink id='product'></AnchorLink>
                    <ProductInfoImage secondary last image={teasers.image2.asset.fluid} title={teasers.heading2}>
                        {teasers.description2}
                    </ProductInfoImage>
                    <ProductInfo image={teasers.image1.asset.fluid} title={teasers.heading1}>
                        {teasers.description1}
                    </ProductInfo>
                </ProductWrapper>
                <BuySection />
                <ContactSection />
            </Layout>
        )
    }
}

export default Index;

export const pageQuery = graphql`
  query {
    seo: sanitySeo {
        title
        description
        image {
            asset {
                fluid {
                    src
                }
            }
        }
    }
    teasers: sanityTeasers {
        heading1
        description1
        image1 {
          asset {
            fluid {
                ...GatsbySanityImageFluid
            }
          }
        }
        heading2
        description2
        image2 {
          asset {
            fluid {
                ...GatsbySanityImageFluid
            }
          }
        }
      }
  }
`
