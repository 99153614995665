import React from 'react';
import styled from 'styled-components';
import BackgroundImage from 'gatsby-background-image';
import { graphql, StaticQuery, Link } from 'gatsby';
import Img from 'gatsby-image';

import Heading from '../UI/Heading';
import Button from '../UI/Button';

const ClipShape = styled.div`
    width: 100%;
    height: calc(100vh + ${props => props.theme.sizes.angledSeparator});
    clip-path: polygon(0 0, 100% 0, 100% 100vh, 0% 100%);
    position: relative;
    z-index: 90;

    @media ${props => props.theme.media.mobileL} {
        height: ${props => props.height + 'px'};
    }
`;

const HeroImageStyled = styled(BackgroundImage)`
    width: 100%;
    height: 100%;
`;

const HeroContent = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${props => props.theme.colors.opacityLight};
    padding: 0 ${props => props.theme.sizes.contentMargin};
    padding-bottom: ${props => props.theme.sizes.angledSeparator};

    @media ${props => props.theme.media.tabletS} {
        flex-direction: column;
        text-align: center;
    }

    @media ${props => props.theme.media.mobileL} {
        padding: 0 ${props => props.theme.sizes.resContentMargin};
    }

    
`;

const ProductImage = styled(Img)`
    width: 50%;
    @media ${props => props.theme.media.tabletS} {
        width: 100%;
    }
`;

const TextWrapper = styled.div`
    width: 50%;
    @media ${props => props.theme.media.tabletS} {
        width: 100%;
    }
`;

const Line = styled.div`
    height: 4px;
    width: 80%;
    background-color: ${props => props.theme.colors.primaryAccent};
    margin: 20px 0;

    @media ${props => props.theme.media.tabletS} {
        margin: 20px auto;
    }
`;


const HeroImage = ({ data, className }) => {
    const heroImage = data.hero.childImageSharp.fluid
    const productImage = data.product.childImageSharp.fluid
    return (
        <ClipShape>
            <HeroImageStyled className={className} fluid={heroImage}>
                <HeroContent>
                    <ProductImage fluid={productImage}/>
                    <TextWrapper>
                        <Heading>Personalise<br /> your workspace</Heading>
                        <Line />
                        <Link to='/#buy'><Button>Buy the Aerotag</Button></Link>
                    </TextWrapper>
                </HeroContent>
            </HeroImageStyled>
        </ClipShape>

    )
}

export default props => (
    <StaticQuery
        query={graphql`
          query {
            hero: file(name: {eq: "hero"}) {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            product: file(name: {eq: "product"}) {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
          }
        `}
        render={data => <HeroImage data={data} {...props} />}
    />
);

